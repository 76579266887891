<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'财务支出'"
      :rules="rules"
    >
      <div class="search" slot="tool">
        <el-input
          style="width: 280px"
          clearable
          placeholder="根据标题查询"
          v-model="searchQuery.title"
          class="searchInput"
        >
          <template slot="prepend">标题</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/finance/payment",
      searchQuery: { title: "" },
      rules: {
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"],
          },
        ],
        expenditureTypeId: [
          {
            required: true,
            message: "请选择类别",
            trigger: ["blur", "change"],
          },
        ],
        expenditureTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: ["blur", "change"],
          },
        ],
        details: [
          {
            required: true,
            message: "请输入详情内容",
            trigger: ["blur", "change"],
          },
        ],
        amount: [
          {
            required: true,
            validator: (relu, value, callback) => {
              if (!value) {
                callback(new Error("请输入金额"));
              } else if (!/^\d+$/.test(value)) {
                callback(new Error("请填写阿拉伯数"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        billNo: [
          {
            required: true,
            message: "请输入订单编号",
            trigger: ["blur", "change"],
          },
        ],
        // receiptImagePath: [
        //   {
        //     required: true,
        //     message: "请上传单据图片",
        //     trigger: ["blur", "change"],
        //   },
        // ],
      },
      props: [
        {
          label: "标题",
          prop: "title",
          align: "center",
        },
        {
          label: "类别",
          prop: "expenditureTypeName",
          align: "center",
        },

        {
          label: "支出时间",
          prop: "expenditureTime",
          align: "center",
        },
        {
          label: "金额",
          prop: "amount",
          align: "center",
        },
        {
          label: "账单编号",
          prop: "billNo",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "标题",
          prop: "title",
          type: "input",
        },
        {
          label: "类别",
          prop: "expenditureTypeId",
          type: "select",
          selectData: [],
        },

        {
          label: "支出时间",
          prop: "expenditureTime",
          type: "date",
        },
        {
          label: "详细内容",
          prop: "details",
          type: "input",
        },

        {
          label: "金额",
          prop: "amount",
          type: "input",
        },
        {
          label: "账单编号",
          prop: "billNo",
          type: "input",
        },

        {
          label: "单据图片",
          type: "upload",
          isFull: true,
          prop: "receiptImagePath",
        },
      ],
    };
  },
  created() {
    this.getTypeList();
  },
  methods: {
    async getTypeList() {
      await api.dictionary
        .getAllList({ type: "财务管理-付款类别" })
        .then((res) => {
          this.formProps[1].selectData = res.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  width: 400px;
}
</style>
